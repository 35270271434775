table {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    padding: 0;
    border-spacing: 0;
    border: 1px solid #D1D5DB;
}

thead {
    background-color: #2563EB;
    border-bottom: 1px solid #D1D5DB;
}

th {
    padding: 24px;
    text-align: left;
    color: #fff;
}

tr {
    border-radius: 8px;
}

td {
    padding: 16px;
    border-bottom: 1px solid #D1D5DB;
}

.center-text {
    text-align: center;
  }