.pagination {
    list-style: none;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    padding: 8px;
    gap: 14px;
}
  
.active {
    background-color: #2563EB;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
}
  
.page-item {
    list-style: none;
    padding: 2px 12px;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    border: 1px solid #DFE4EA;
    border-radius: 8px;
    padding: 8px;
}

.next-label {
    border-radius: 8px;
    padding: 6px;
    border: 1px solid #DFE4EA; 
}

.previous-label {
    border-radius: 8px;
    padding: 6px;
    border: 1px solid #DFE4EA; 
}